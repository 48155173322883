<template>
  <el-dialog title="新建银行账户"
             width="50%"
             :close-on-click-modal="false"
             :close-on-press-escape="false"
             @close="$emit('close', '')"
             v-model="dialogShow">
    <el-steps :active="step" finish-status="success" align-center>
      <el-step title="账号信息"/>
      <el-step title="手机验证"/>
    </el-steps>
    <el-row justify="center" v-loading="$store.getters.isLoading">
      <el-col :span="20">
        <template v-if="step < 1">
          <el-form
              ref="formRef"
              label-position="right"
              label-width="135px"
              :model="form" :rules="rules">
            <el-form-item label="银行卡号" prop="acctno">
              <el-input v-model="form.acctno"></el-input>
            </el-form-item>
            <el-form-item label="证件类型" prop="idtype">
              <el-select v-model="form.idtype">
                <el-option value="0" label="身份证"/>
                <el-option value="2" label="护照"/>
                <el-option value="5" label="港澳通行证"/>
                <el-option value="6" label="台湾通行证"/>
              </el-select>
            </el-form-item>
            <el-form-item label="证件号" prop="idno">
              <el-input v-model="form.idno"></el-input>
            </el-form-item>
            <el-form-item label="银行卡预留姓名" prop="acctname">
              <el-input v-model="form.acctname"></el-input>
            </el-form-item>
            <el-form-item label="银行卡预留手机号" prop="mobile">
              <el-input v-model="form.mobile"></el-input>
            </el-form-item>
          </el-form>
        </template>
        <template v-else>
          <el-form
              ref="formRef"
              label-position="top"
              :model="form" :rules="rules">
            <el-form-item style="margin-bottom: 0;" label="为了你的账户安全，请验证身份">
            </el-form-item>
            <el-form-item>
              <el-input style="width: 100%" disabled :placeholder="`使用手机${form.mobile}验证`"></el-input>
            </el-form-item>
            <el-form-item prop="smscode">
              <el-row justify="space-around" :gutter="20">
                <el-col :span="18">
                  <el-input v-model="form.smscode" placeholder="请输入验证码"></el-input>
                </el-col>
                <el-col :span="6">
                  <el-row justify="end">
                    <el-button @click="submitInfo" :disabled="countDown > 0">
                      {{ countDown > 0 ? `${countDown}s后重新获取` : '获取验证码' }}
                    </el-button>
                  </el-row>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
          <!--      手机验证 -->
        </template>

      </el-col>
    </el-row>
    <template #footer>
      <el-button @click="dialogShow = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </template>
  </el-dialog>
</template>

<!--自定义银联支付-->
<script setup>
import {ref} from "vue";
import finance from "@/request/api/finance";
import {ElMessage} from "element-plus";

const props = defineProps({startStep: {default: 0, type: Number}})

const step = ref(props.startStep > 0 ? props.startStep : 0)
const dialogShow = ref(true)

const formRef = ref()

const form = ref({
  acctno: '',
  idtype: '',
  idno: '',
  acctname: '',
  mobile: '',
  id: '',
  smscode: ''
})

const rules = {
  acctno: [{required: true, message: '请输入银行卡号', trigger: 'blur'}],
  idtype: [{required: true, message: '请选择证件类型', trigger: 'blur'}],
  idno: [{required: true, message: '请输入证件号', trigger: 'blur'}],
  acctname: [{required: true, message: '银行卡预留姓名', trigger: 'blur'}],
  mobile: [{required: true, message: '银行卡预留手机号', trigger: 'blur'}],
  smscode: [{required: true, message: '请输入验证码', trigger: 'blur'}],
}

const submit = async () => {
  const result = await formRef.value.validate()
  if (true !== result) return
  // 账号信息
  if (step.value < 1) {
    if (submitInfo()) step.value = 1
    return
  }

  let res = await finance.submitTonglianBankcardWithVerify(form.value.id, form.value.smscode)
  console.log(res)
  if (res.code == 200) {
    ElMessage.success('绑定成功')
    location.reload()
    return
  }
  ElMessage.error('绑定失败')
}

const countDown = ref(0)

const startCountDown = () => {
  countDown.value = 59
  const interval = setInterval(() => {
    countDown.value--
    if (countDown.value <= 0) {
      clearInterval(interval)
      countDown.value = 0
    }
  }, 1000)
}

const submitInfo = async () => {
  const res = await finance.submitTonglianBankcard({
    id: form.value.id,
    acctno: form.value.acctno,
    idtype: form.value.idtype,
    idno: form.value.idno,
    acctname: form.value.acctname,
    mobile: form.value.mobile,
  })

  if (!res.id) return 0

  form.value.id = res.id
  startCountDown()
}

</script>

<style scoped>
.el-form {
  padding-top: 20px;
}
</style>