<template>
  <el-row :gutter="20" class="payment-checkout">
    <el-col :sm="24" :md="14">
      <el-row class="title">
        银行账号
        <el-col :span="24" class="subtitle">请选择本次用于付款的银行账号</el-col>
      </el-row>
      <el-radio-group class="bankcard-list" v-model="form.bankcard_id">
        <el-row>
          <el-col :span="24" v-for="card in bankcards" :key="card.id">
            <el-radio class="bankcard-item"
                      border
                      :label="card.id"
                      @change="form.mobile = card.mobile">
              <el-row class="bankcard-item-content" :gutter="20" align="middle" justify="space-around">
                <el-col :span="20">
                  <div class="info">{{ card.acctno }}（手机号：{{ card.mobile }}）</div>
                  <div class="describe">{{ card.bankname }}</div>
                </el-col>
                <el-col :span="4">
                  <el-row justify="end">
                    <el-link type="primary" :underline="false" @click="unBind(card)">解绑</el-link>
                  </el-row>
                </el-col>
              </el-row>
            </el-radio>
          </el-col>
        </el-row>
      </el-radio-group>
      <el-link type="primary"
               :underline="false"
               @click="dialogType = 'add'">
        <i class="el-icon-plus"></i>添加银行账号
      </el-link>

    </el-col>
    <el-col :sm="24" :md="7">
      <el-card class="box-card payment-form" shadow="never">
        <el-form label-position="left" label-width="80px">
          <el-row class="title">订单信息</el-row>
          <el-form-item label="收款人">
            <el-row justify="end" class="payment-form-content">YinoLink易诺</el-row>
          </el-form-item>
          <el-form-item label="订单号">
            <el-row justify="end" class="payment-form-content">{{ recharge.recharge_no }}</el-row>
          </el-form-item>
          <el-form-item label="订单信息">
            <el-row justify="end" class="payment-form-content">YinoLink广告费付款</el-row>
          </el-form-item>
          <el-divider/>
          <el-form-item label="订单金额">
            <el-row justify="end" class="payment-form-content">USD {{ recharge.dollar }}</el-row>
          </el-form-item>
          <el-divider/>
          <el-row class="payment-form-content" justify="space-around">
            <el-col :span="12">应付金额</el-col>
            <el-col :span="12" class="payment-amount-box">
              <span>CNY <span class="payment-amount">{{ recharge.amount }}</span></span>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-button
          class="checkout-payment-btn"
          type="primary"
          :disabled="!form.bankcard_id"
          @click="submit">确认付款
      </el-button>
    </el-col>
  </el-row>
  <add-union v-if="dialogType === 'add'" @close="dialogType = ''"/>
  <verify-union v-if="dialogType==='verify'"
                :mobile="form.mobile"
                :recharge-no="form.recharge_no"
                :bankcard-id="form.bankcard_id"
                @close="dialogType = ''"/>
</template>

<!--自定义银联支付-->
<script setup>
import {onMounted, ref} from "vue";
import finance from "@/request/api/finance";
import {ElMessageBox, ElMessage} from "element-plus";
import AddUnion from "@/views/finance/checkout/AddUnion";
import VerifyUnion from "@/views/finance/checkout/VerifyUnion";

const props = defineProps({
  id: {required: true}
})

const bankcards = ref([])
const recharge = ref({
  amount: '--',
  dollar: '--',
  receive_type: '',
  recharge_no: '--'
})

const form = ref({
  recharge_no: props.id,
  bankcard_id: '',
  smscode: '',
  mobile: '',
})

const dialogType = ref('')
onMounted(async () => {
  let res = await finance.tongLianBankcard(props.id)
  bankcards.value = res.bankcard
  recharge.value = res.recharge
})

const unBind = async card => {
  const confirmResult = await ElMessageBox.confirm(`您确定要解绑银行账号：${card.acctno}（${card.bankname}）吗？解绑后需重新进行认证，认证成功后方可用于支付。`, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).catch(err => err)

  if (confirmResult !== 'confirm') {
    console.log('cancle')
    return
  } else {
    form.value.bankcard_id = '';
    let res = await finance.unBindTonglianBankcard(card.id)
    if (res.code != 200) return ElMessage.error('解绑失败')
    ElMessage.success('解绑成功')
    location.reload()
  }
}

const submit = async () => {
  if (!form.value.recharge_no) return ElMessage.error('订单状态异常')
  if (!form.value.bankcard_id) return ElMessage.error('请先选择银行账户')
  let res = await finance.startTongLianPay(form.value.recharge_no, form.value.bankcard_id)
  if (res.code !== 200) {
    return ElMessage.error('提交支付失败，请重试')
  }

  dialogType.value = 'verify'
}

</script>

<style scoped>
.payment-checkout .title {
  font-weight: 600;
  line-height: 44px;
}

.payment-checkout .title .subtitle {
  line-height: 1;
  font-size: 10px;
  font-weight: 400;
  color: var(--el-text-color-regular)
}

.payment-form.el-card {
  border-radius: 0 !important;
}

.payment-form .el-form-item {
  margin-bottom: 0 !important;
  height: 44px;
}

.payment-form .payment-form-content {
  font-size: 14px;
  font-weight: 600 !important;
}

.payment-form .payment-amount-box {
  padding-top: 44px;
  justify-content: flex-end;
  display: flex;
}

.payment-form .payment-amount-box .payment-amount {
  padding-left: 5px;
  font-size: 20px !important;
  color: #1677ff !important;
}

.bankcard-list {
  padding: 20px 20px 20px 0;
}

.bankcard-list .bankcard-item {
  width: 100% !important;
  padding-top: 15px;
  padding-bottom: 15px;
  height: auto;
  margin-bottom: 20px;
}

.bankcard-list .bankcard-item .describe {
  font-size: 10px;
}

.checkout-payment-btn {
  margin-top: 20px;
  width: 100% !important;
}
</style>

<style>
.bankcard-list .bankcard-item .el-radio__label {
  width: 100%;
}
</style>